import * as React from 'react';
import ArtistLink from '../artist-link';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import * as artistActions from '../../redux/actions/artistActions'

class ArtistsCard extends React.Component<any, any>
{
    state = {
        artists: []
    }

    componentDidMount() {
        if(this.state.artists.length === 0) {
            this.props.actions.getPopularArtists().catch((error: string) => {
                console.log('loading failed:' + error)
            })
        }
    }

    render() {
        let artists = this.props.artists?.slice(0, 10);
        let artistsHTML = artists?.map(function(item: any) {
            return (
                <div key={item.id}>
                    <ArtistLink artist={item}></ArtistLink>
                </div>
            );
        });

        return (
            <div style={styles.container}>
                    <h2>
                        Most Popular Artists&nbsp;
                        <PeopleAltIcon style={{ marginBottom: -5 }}></PeopleAltIcon>
                    </h2>
                    <div style={styles.items}>
                        {artistsHTML && artistsHTML.length > 0 ? artistsHTML : <CircularProgress />}
                    </div>
                    <div>
                        <a style={{width: '100px'}} href={'/artists'}>all artists</a>
                    </div>
            </div>
        );
    }
}

function mapStateToProps(state: { artists: any; }) {
    return {
        artists: state.artists
    }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        actions: bindActionCreators(artistActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistsCard)

const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        textAlign: 'center',
    } as React.CSSProperties,
    items: {
        textAlign: "left",
        padding: '20px'
    } as React.CSSProperties,
}