import * as React from 'react';
import { NavLink } from 'react-router-dom';

function getCookie(cname: any) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

class ArtistLink extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        var langCookie = getCookie('lang')

        this.state = {
            artist: props.artist,
            lang: langCookie
        }
    }

    componentDidMount() {
    }

    // don't need to use it. code sample left here in case
    // static getDerivedStateFromProps(props: any, state: any) {
    //     const artist = props.artist;

    //     if(!artist) return null;

    //     return {
    //         artist: artist,
    //     }
    // }

    render() {

        const artist = this.state.artist;

        let name;
        if(this.state.lang === "mm") {
            name = artist?.displayName
        } else {
            name = artist?.nameInBurglish
        }

        return (
            <NavLink
                style={{color: "white"}}
                to={`/artist/${artist.id}/${artist.nameInBurglish.replace(/\s+/g, '-')}`}
            >
                {name}
            </NavLink>
        );
    }
}

export default ArtistLink