import React from 'react';
import poolData from '../services/config';

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { Button, Container, TextField, withStyles } from '@material-ui/core';

const styles = (theme: any) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '35ch',
        },
    },
    textField: {
        '& .MuiInputBase-input': {
            color: 'white',
        },
        width: '35ch',
    }
});

class Login extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
            email: '',
            password: ''
        }
    }

    componentDidMount() {
        document.title = `Myanmar Lyrics | Login`;
    }

    handleChange = (event: any) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }

    login = () => {
        const _this = this;

        const email = _this.state.email;
        const password = _this.state.password;

        if (!(email && password)) {
            alert('missing fields on the form')
            return;
        }

        let authenticationData = {
            Username: email,
            Password: password,
        };
        let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
            authenticationData
        );
        const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        let userData = {
            Username: email,
            Pool: userPool,
        };
        let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {

                // let groups = result.getAccessToken().decodePayload()['cognito:groups'];

                // let isAdminFunc = function(list: any) {
                //     return list?.some(function (item: any) {
                //         if(item === 'admin') {
                //             return true;
                //         }
                //         return false;
                //     });
                // }

                // let isAdmin = isAdminFunc(groups);

                // let accessToken = result.getAccessToken().getJwtToken();
                // console.log('token: ' + accessToken);

                // cognitoUser.getUserData(function (err, userData) {
                //     if(err) {
                //         console.log(err.message || JSON.stringify(err));
                //         return;
                //     }
                //     console.log(userData);
                // })
                document.location.href = "/";
            },

            onFailure: function (err) {
                if (err.code === "UserNotConfirmedException") {
                    alert('Please confirm your email to login. please check your spam or junk mail folder.');
                } else {
                    alert(err.message || JSON.stringify(err));
                }
            },
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Container>
                <div>
                    <h1>Login</h1>
                    <form className={classes.root} autoComplete="off">
                        <div>
                            <TextField
                                className={classes.textField}
                                onChange={this.handleChange}
                                required
                                name="email"
                                placeholder="Email"
                                id="username"
                                type="text"
                                inputProps={{ maxLength: 120 }}>
                            </TextField>
                        </div>
                        <div>
                            <TextField
                                className={classes.textField}
                                onChange={this.handleChange}
                                required
                                name="password"
                                placeholder="Password"
                                id="password"
                                type="password"
                                inputProps={{ maxLength: 20 }}>
                            </TextField>
                        </div>
                        <Button variant="contained" color="primary"
                            type="button"
                            onClick={this.login}>
                            Login
                            </Button>
                            &nbsp;
                            <a
                            href={'/forgotpassword'}
                        >
                            Forgot Password?
                            </a>
                    </form>
                </div>
            </Container>
        );
    }
}

export default withStyles(styles)(Login)