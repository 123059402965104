import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import FacebookIcon from '@material-ui/icons/Facebook';
import LyricsImage from '../components/lyricsImage';
import FavouriteIcon from '../components/favouriteIcon';
import ArtistLink from '../components/artist-link';
import * as apiService from '../services/api'
import AdComponent from '../components/adComponent';

function getCookie(cname: any) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

const Song = () => {

    const { id }: any = useParams();
    const [song, setSong] = useState<any>({})

    useEffect(() => {
        if (!id) return;
        apiService.getSongById(id)
            .then(
                (result) => {
                    setSong(result);
                    document.title = `Myanmar Lyrics | ${result.title}`;
                }
            )
    }, [])

    const shareOnFacebook = () => {
        let shareUrl = window.location.href;
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, "pop", "width=600, height=400, scrollbars=no");
        return false;
    }

    const artists = song.artists?.map(function (item: any) {
        return (
            <div key={item.id}>
                <ArtistLink artist={item}></ArtistLink>
            </div>
        )
    });

    // const links = this.state.song.links?.map(function (item: any) {
    //     return (
    //         <div key={item.url}>
    //             <div dangerouslySetInnerHTML={item.url}></div>
    //         </div>
    //     )
    // });

    let link;
    if (song.link) {
        link = { __html: song.link };
    }

    let lyrics = <div style={styles.bottom}>
        {!song.lyrics ? (
            <div style={styles.image}>
                <LyricsImage song={song}></LyricsImage>
            </div>
        ) : ''}

        {song.lyrics ? (
            <div
                style={styles.lyrics}
                dangerouslySetInnerHTML={{ __html: song.lyrics }}>
            </div>
        ) : ''}

        <div dangerouslySetInnerHTML={link}></div>
    </div>;

    const fbShare = <div style={styles.share} onClick={shareOnFacebook}>
        share on <FacebookIcon></FacebookIcon>
    </div>

    const lang = getCookie('lang')

    return (
        <div style={styles.container}>
            <div>
                <h1 style={{ padding: '10px' }}>
                    {lang === 'mm' ? song?.displayTitle : song?.title}
                </h1>

                <FavouriteIcon song={song}></FavouriteIcon>

                <div>
                    <a href={'/album/' + song.album?.titleInBurglish?.replace(/\s+/g, '-')}>{song?.album?.displayTitle}</a>
                </div>

                <div style={{ padding: '10px' }}>
                    <h3>Artists</h3>
                    {artists}
                </div>
                {fbShare}
                <AdComponent></AdComponent>
                {lyrics}
            </div>
        </div>
    )
}

export default Song

const styles = {
    container: {
        paddingTop: 20 + 'px',
        display: 'flex',
        flexDirection: 'column',
        WebkitUserSelect: 'none', /* Safari */
        MozUserSelect: 'none', /* Firefox */
        MsUserSelect: 'none', /* IE10+/Edge */
        userSelect: 'none', /* Standard */
        textAlign: 'center'
    } as React.CSSProperties,
    lyrics: {
        padding: '50px',
        backgroundColor: '#282C34',
        width: '80%',
    } as React.CSSProperties,
    bottom: {
        display: 'flex',
    } as React.CSSProperties,
    image: {
        marginTop: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
    } as React.CSSProperties,
    share: {
        backgroundColor: '#3b5998',
        width: '120px',
        marginLeft: 'auto',
        marginRight: 'auto',
        cursor: 'pointer',
        marginBottom: '10px',
    }
}