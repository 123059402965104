import { Container } from '@material-ui/core';
import React from 'react';

class Compatibility extends React.Component<any, any>
{
    componentDidMount() {
        document.title = `Myanmar Lyrics | Browser Compatibility And Font`;
    }

    render() {
        const browserCompatibility = <div>
            <h2>Browser Compatibility</h2>
            <p>Make sure Javascript is enabled on your browser.</p>
            <p>
                We support most major browsers including Chrome, Edge, Safari, Firefox on Desktop computers including Windows and iOS.
                                            <ul>
                    <li>Chrome 32 or later</li>
                    <li>Edge 18 or later</li>
                    <li>Firefox 65 or later</li>
                    <li>Safari 14 or later (make sure you have latest Mac OS version</li>
                </ul>
            </p>
            <p>
                We support most major mobile browsers including iOS Safari, Android browser, Chrome for Android and Chrome for iOS.
                                            <ul>
                    <li>iOS Safari 14 or later</li>
                    <li>Android Browser 81 or later</li>
                    <li>Chrome for Android 87 or later</li>
                    <li>Firefox for Android 83 or later</li>
                </ul>
            </p>
            <p>
                <i>We have dropped support for <b>Internet Explorer</b>. Internet Explorer is legacy browser from Microsoft and even Microsoft
                is dropping support for Internet Explorer. We developed our site using latest Javascript and front end technology and in order
                to do that we cannot keep supporting old legacy browsers such as Internet Explorer.</i>
            </p>
        </div>

        const fontInfo = <div>
            <h2>Myanmar Font</h2>
            <p>We use Unicode Myanmar font for all Myanmar texts used in the site.</p>
            <p>We do not support Zawgyi font.</p>
        </div>

        return (
            <Container>
                <h1>Compatibility</h1>
                {browserCompatibility}
                {fontInfo}
            </Container>

        );
    }
}

export default Compatibility