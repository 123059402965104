import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AdComponent from '../components/adComponent'
import ArtistLink from '../components/artist-link'
import SongLink from '../components/song-link'
import * as apiService from '../services/api'

const Album = () => {

    const { title }: any = useParams();
    const titleClean = title.replace(/-/g, ' ')

    const [album, setAlbum] = useState<any>({})

    useEffect(() => {
        apiService.getAlbumByTitle(titleClean)
            .then(
                (result) => {
                    setAlbum(result);
                    document.title = `Myanmar Lyrics | ${result.displayTitle}`
                }
            )
    }, [])

    let artists = album.artists?.map(function (item: any) {
        return (
            <div key={item.id}>
                <ArtistLink artist={item}></ArtistLink>
            </div>
        );
    });

    let songs = album.songs?.map(function (item: any) {
        return (
            <div key={item.id}>
                <SongLink song={item}></SongLink>
            </div>
        )
    });

    return (
        <div style={styles.container}>
            <div>
                <h1>{album.displayTitle}</h1>
                <h2>Artists</h2>
                {artists}
                <AdComponent></AdComponent>
                <hr></hr>
                <h2>Songs</h2>
                {songs}
            </div>
        </div>
    )
}

export default Album

const styles = {
    container: {
        paddingTop: '30px',
        textAlign: 'center',
        lineHeight: '35px'
    } as React.CSSProperties,
}
