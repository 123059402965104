import React from "react";
import AdComponent from "./adComponent";
import ArtistLink from "./artist-link";

const AlbumList = (props: any) => {

    let albums = props.albums?.map(function (item: any, index: any) {
        return (
            <div key={index}>
                <h1><a href={'/album/' + item.displayTitle?.replace(/\s+/g, '-')}>{item.displayTitle}</a></h1>
                <span>
                    {item.artists?.map(function (artist: any) {
                        return (
                            <div key={artist.id}>
                                &nbsp;<ArtistLink artist={artist}></ArtistLink>
                            </div>
                        )
                    })}
                </span>
                <AdComponent></AdComponent>
            </div>
        );
    });

    return (
        <div>{albums}</div>
    )
}

export default AlbumList