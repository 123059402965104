import * as React from 'react';
import { NavLink } from 'react-router-dom';

function getCookie(cname: any) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

class SongLink extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        var langCookie = getCookie('lang')

        this.state = {
            song: props.song,
            lang: langCookie
        }
    }

    // don't need to use it. code sample left here in case
    // static getDerivedStateFromProps(props: any, state: any) {
    //     const song = props.song;

    //     if(!song) return null;

    //     return {
    //         song: song,
    //     }
    // }

    render() {

        const song = this.state.song;

        let name;
        if(this.state.lang === "mm") {
            name = song?.displayTitle
        } else {
            name = song?.title
        }

        return (
            <div>
                <NavLink
                    style={{color: "white"}}
                    to={`/song/${song?.id}/${song?.title.replace(/\s+/g, '-')}`}>
                        {name}
                </NavLink>
            </div>
        );
    }
}

export default SongLink