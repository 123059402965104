import React from 'react';
import { CircularProgress, Container } from '@material-ui/core';
import ArtistLink from '../components/artist-link';
import SongLink from '../components/song-link';
import * as apiService from '../services/api'
import AdComponent from '../components/adComponent';

class MostFavourited extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
            songs: []
        }
    }

    componentDidMount() {
        apiService.getMostFavourited()
            .then(
                (result) => {
                    this.setState({
                        songs: result
                    });
                }
            );

        document.title = `Myanmar Lyrics | Most Favourited Songs`;
    }

    render() {
        const mostFavourited = this.state.songs?.map(function (item: any) {
            return (
                <div key={item.id} style={styles.song}>
                    <div style={styles.linka}>
                        <SongLink song={item}></SongLink>
                    </div>
                    {
                        item.artists?.map(function (item: any) {
                            return (
                                <div key={item.id}>
                                    by - <ArtistLink style={styles.link} artist={item}></ArtistLink>
                                </div>
                            )
                        })
                    }
                </div>
            )
        })

        return (
            <Container>
                <div>
                    <h1>Top 50 Most Favourited Songs</h1>
                    <AdComponent></AdComponent>
                    {mostFavourited && mostFavourited.length > 0 ? mostFavourited : <CircularProgress />}
                </div>
            </Container>
        );
    }
}

export default MostFavourited

const styles = {
    song: {
        lineHeight: '175%',
        padding: '10px',
        marginBottom: '10px',
        backgroundColor: '#29333d',
    } as React.CSSProperties,
    link: {
        color: 'white',
        textDecoration: 'none',
        paddingRight: '10px'
    } as React.CSSProperties,
    linka: {
        textDecoration: 'none',
        fontSize: 'large',
        fontWeight: 'bold'
    } as React.CSSProperties,
}