const apiUrl = `${process.env.REACT_APP_API_URL}`;

export function getPopularArtists() {
    return fetch(`${apiUrl}artist/popular?limit=10`)
        .then(res => res.json())
}

export function getArtistByName(name) {
    return fetch(`${apiUrl}artist/getbyname?id=${name}`)
        .then(res => res.json())
}

export function getArtistsByType(type) {
    return fetch(`${apiUrl}artist/${type}`)
        .then(res => res.json())
}

export function getalbumsForCard() {
    return fetch(`${apiUrl}album/getall?limit=10`)
        .then(res => res.json())
}

export function getlatestuploads(type) {
    return fetch(`${apiUrl}song/getlatestuploads?limit=10`)
        // fetch('https://1s0fsdn2jf.execute-api.eu-west-1.amazonaws.com/lyrics/song')
        .then(res => res.json())
}

export function getallalbums() {
    return fetch(apiUrl + 'album/getall')
        .then(res => res.json())
}

export function getAlbumByTitle(title) {
    return fetch(`${apiUrl}album/getbytitle?title=${title}`)
        .then(res => res.json())
}

export function getMostFavourited() {
    return fetch(`${apiUrl}song/mostfavourited`)
        .then(res => res.json());
}

export function getSongById(id) {
    let queryUrl = `${apiUrl}song/getbyid?id=${id}`;
    return fetch(queryUrl)
        .then(res => res.json())
}

export function getMostPopular() {
    return fetch(`${apiUrl}song/mostpopular`)
        .then(res => res.json())
}

export function getSearchResult(searchTerm, usergroup) {
    return fetch(`${apiUrl}search?term=${searchTerm}&usergroup=${usergroup}`)
        .then(res => res.json())
}

export function getUserUploads(username) {
    return fetch(`${apiUrl}user/uploads?userid=${username}`)
        .then(res => res.json())
}

export function getUserFavourites(username) {
    return fetch(`${apiUrl}user/favourites?username=${username}`)
        .then(res => res.json())
}

export function userFavourite(formData) {
    return fetch(`${apiUrl}user/favourite`, {
        method: 'POST',
        body: formData
    })
}

export function isFavourited(username, songid) {
    return fetch(`${apiUrl}user/isfavourited?username=${username}&songid=${songid}`)
        .then(res => res.json())
}
