import * as React from 'react';
import ArtistLink from '../components/artist-link';
import SongLink from '../components/song-link';
import * as apiService from '../services/api'

class MostPopular extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
            songs: []
        }
    }

    componentDidMount() {
        apiService.getMostPopular()
        .then(
            (result) => {
                this.setState({
                    songs: result
                });
            }
        );

        document.title = `Myanmar Lyrics | Most Popular`;
    }

    render() {

        const artists = this.state.songs?.map(function (item: any) {
            return (
                <div style={styles.song}>
                    <div style={styles.linka}>
                        <SongLink song={item}></SongLink>
                    </div>
                    {
                        item.artists?.map(function (item: any) {
                            return (
                                <div style={styles.link}>
                                    <ArtistLink artist={item}></ArtistLink>
                                </div>
                            )
                        })
                    }
                </div>
            )
        })

        const body = <div>
                        <h1>Top 50</h1>
                        {artists}
                    </div>

        return (
            <div style={styles.container}>
                {body}
            </div>
        );
    }
}

export default MostPopular

const styles = {
    container: {
        padding: '20px',
    } as React.CSSProperties,
    song: {
        lineHeight: 'normal',
        display: 'flex',
    } as React.CSSProperties,
    link: {
        color: 'white',
        textDecoration: 'none',
        paddingRight: '10px'
    } as React.CSSProperties,
    linka: {
        textDecoration: 'none',
        width: '50%',
    } as React.CSSProperties,
}