import * as React from 'react';
import ChangePassword from '../components/auth/changePassword';
import CognitoService from '../services/cognitoService';
import poolData from '../services/config';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { Container } from '@material-ui/core';

class User extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        let isLoggedIn = false;
        const userid = CognitoService.getUserId();

        if (userid) {
            isLoggedIn = true;
        }

        this.state = {
            isLoggedIn: isLoggedIn
        }
    }

    componentDidMount() {
        document.title = `Myanmar Lyrics | User`;
    }

    signOut = () => {
        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        let cognitoUser = userPool.getCurrentUser();
        cognitoUser?.signOut();

        document.location.href = "/";
    }

    render() {
        let loggedInView = <div style={styles.container} className='container'>
            <div style={styles.section}>
                <ChangePassword></ChangePassword>
            </div>
        </div>;

        let guestView = <div>
            you are not logged in.
        </div>

        let body;

        if (this.state.isLoggedIn) {
            body = loggedInView;
        } else {
            body = guestView;
        }

        return (
            <Container>
                {body}
            </Container>
        );
    }
}

export default User;

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    } as React.CSSProperties,
    section: {
    } as React.CSSProperties,
}