import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

import ArtistTypeSelector from '../components/artist-type-selector';
import ArtistsType from '../components/artists-type';
import { Container } from '@material-ui/core';
import AdComponent from '../components/adComponent';

class Artists extends React.Component<any, any>
{
    render() {
        return (
            <Container>
                <h1>Artists&nbsp;<PeopleAltIcon></PeopleAltIcon></h1>
                <AdComponent></AdComponent>
                <ArtistTypeSelector></ArtistTypeSelector>

                <Switch>
                    <Route path="/artists/:type" component={ArtistsType}></Route>
                    <Route path="/artists" component={ArtistsType}></Route>
                </Switch>
            </Container>
        );
    }
}

export default Artists
