import * as React from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import { NavLink } from 'react-router-dom';

class Footer extends React.Component
{
    render() {
        return (
            <footer style={footer}>
                <div>{new Date().getFullYear()} &copy; MyanmarLyrics.Net. All rights reserved.</div>
                <div>Visit <a href={'/terms'}><b><i>terms</i></b></a> for our full terms and conditions.</div>
                <div><a href={'/compatibility'}>site compatibility</a></div>
                <div><a href='http://fb.me/mmrlyrics' target='_blank' rel="noreferrer"><FacebookIcon style={{fontSize: '3em'}}></FacebookIcon></a></div>
                <div>
                    <NavLink to="/artists">artists</NavLink>&nbsp;|
                    &nbsp;<NavLink to="/albums">albums</NavLink>&nbsp;|
                    &nbsp;<NavLink to="/mostfavourited">mostfavourited</NavLink>
                </div>
            </footer>
        );
    }
}

export default Footer

const footer = {
    marginTop:'calc(85vh - 100px)',
    backgroundColor: 'grey',
    textAlign: 'center',
    width: '100%',
    bottom: 0,
} as React.CSSProperties;