import React from 'react';
import { CircularProgress, InputLabel, MenuItem, Select } from '@material-ui/core';
import ArtistLink from './artist-link';
import * as apiService from '../services/api'

class ArtistsType extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
            type: props.match.params.type,
            artist: [],
            artists: [],
            filtered: [],
            az: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'],
            selectedAZ: '',
            showLoading: false,
        }
    }

    static getDerivedStateFromProps(props: any, state: any) {
        const type = props.match.params.type;
        if (state.type !== type) {
            return {
                type: type
            }
        }
        return null;
    }

    // componentDidMount only get called once
    // componentDidUpdate will get triggered on state change from getDerivedStateFromProps
    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.type !== this.state.type) {
            this.fetchData(this.state.type)
        }
    }

    componentDidMount() {
        let type = 'all'
        if (this.state.type) type = this.state.type
        this.fetchData(type)
        document.title = `Myanmar Lyrics | Artists`
    }

    fetchData(type: string) {
        this.setState({ showLoading: true })
        apiService.getArtistsByType(type || 'all')
            .then(
                (result) => {
                    this.setState({ artists: result, filtered: result, selectedAZ: '', showLoading: false })
                }
            )
    }

    handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedAZ = event.target.value as string
        this.setState({ selectedAZ: selectedAZ })

        if (selectedAZ && this.state.artists && this.state.artists.length > 0) {
            const filtered = this.state.artists?.filter((a: any) => {
                return a.nameInBurglish.startsWith(selectedAZ)
            })
            this.setState({ filtered: filtered })
        }
    };

    render() {
        let artistsHTML = this.state.filtered?.map(function (item: any) {
            return (
                <div key={item.id}>
                    <div>
                        <ArtistLink artist={item}></ArtistLink>
                    </div>
                </div>
            );
        });

        const az = this.state.az.map((item: any) => {
            return (
                <MenuItem value={`${item}`}>{item}</MenuItem>
            )
        })

        return (
            <div style={styles.container}>
                <div style={{ color: 'white', paddingBottom: '20px' }}>
                    <InputLabel style={{ color: 'white' }} id="demo-simple-select-label">A-Z</InputLabel>
                    <Select
                        value={this.state.selectedAZ}
                        displayEmpty
                        style={{ color: 'white' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={this.handleChange}
                    >
                        {az}
                    </Select>
                </div>
                <div>
                    {this.state.showLoading ? <CircularProgress /> : null}
                    {artistsHTML && artistsHTML.length > 0 ? artistsHTML : (this.state.showLoading ? null : 'no result')}
                </div>
            </div>
        );
    }
}

export default ArtistsType

const styles = {
    container: {
        padding: '20px',
        lineHeight: '200%'
    } as React.CSSProperties,
}