import { Button, ButtonGroup } from '@material-ui/core';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

class ArtistTypeSelector extends React.Component<any, any>
{
    render() {

        return (
            <div>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                    <Button><NavLink style={{ textDecoration: 'none'}} to={'/artists/all'}>All Artists</NavLink></Button>
                    <Button><NavLink style={{ textDecoration: 'none'}} to={'/artists/male'}>Male</NavLink></Button>
                    <Button><NavLink style={{ textDecoration: 'none'}} to={'/artists/female'}>Female</NavLink></Button>
                    <Button><NavLink style={{ textDecoration: 'none'}} to={'/artists/group'}>Group</NavLink></Button>
                </ButtonGroup>
            </div>
        );
    }
}

export default ArtistTypeSelector