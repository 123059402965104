import React from 'react';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import CognitoService from '../services/cognitoService';
import { Tooltip } from '@material-ui/core';

import * as apiService from '../services/api'
import { CognitoUserSession } from 'amazon-cognito-identity-js';

class FavouriteIcon extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
            isFavourited: false
        }
    }

    componentWillReceiveProps(props: any) {

        const _this = this;

        const username = CognitoService.getUserId();
        const songid = props.song.id;

        if (!(songid && username)) return;

        this.isfavourited(username, songid, function (result: any) {
            _this.setState({
                isFavourited: result
            });
        });
    }

    favouriteToggle = (id: any) => {

        const _this = this;

        const user = CognitoService.getUser()
        if (!user) {
            alert('you need to login or register to favourite songs')
            return;
        };

        if(user) {
            user.getSession(function (err: any, session: CognitoUserSession) {
                if(err) {
                    console.log(err);
                    return;
                }

                let formData = new FormData();

                formData.append('songid', id);
                formData.append('username', user.getUsername())
                formData.append('token', session.getAccessToken().getJwtToken())

                apiService.userFavourite(formData)
                    .then(data => {
                        _this.componentWillReceiveProps(_this.props);
                    });
            })
        }
    }

    heartIcon = (song: any) => {
        const isFavourited = this.state.isFavourited;
        if (isFavourited) {
            return (
                <Tooltip title="remove from favourite" aria-label="remove from favourite" placement="right">
                    <FavoriteIcon style={{ fontSize: '4em' }} onClick={() => this.favouriteToggle(song.id)}></FavoriteIcon>
                </Tooltip>

            )
        } else {
            return (
                <Tooltip title="add to favourite" aria-label="add to favourite" placement="right">
                    <FavoriteBorderIcon style={{ fontSize: '4em' }} onClick={() => this.favouriteToggle(song.id)}></FavoriteBorderIcon>
                </Tooltip>

            )
        }
    }

    private isfavourited(username: string, songid: any, callback: any) {
        apiService.isFavourited(username, songid)
            .then((result) => {
                callback(result);
            });
    }

    render() {
        return (
            <div>
                {this.heartIcon(this.props.song)}
            </div>
        );
    }
}

export default FavouriteIcon