import React, { useState } from 'react'
import { CircularProgress, Container } from '@material-ui/core'
import CognitoService from '../services/cognitoService';
import ArtistLink from './artist-link';
import SongLink from './song-link';
import * as apiService from '../services/api'

const SearchResult = () => {

    const [term, setTerm] = useState<any>(null);
    const [resultView, setResultView] = useState<any>(null);

    const isAdmin = CognitoService.isAdmin();

    var url_string = window.location.href
    var url = new URL(url_string);
    var query = url.searchParams.get("q");

    if (term !== query) {
        setResultView(<CircularProgress />)
        setTerm(query)
        apiService.getSearchResult(query, isAdmin ? 'admin' : '')
            .then(
                (result) => {
                    getResult(result)
                }
            );
    }

    const getResult = (result: any) => {
        const artists = result.artists?.map(function (item: any) {
            return (
                <div key={item.id}>
                    <ArtistLink artist={item}></ArtistLink>
                </div>
            )
        });

        const albums = result.albums?.map(function (item: any) {
            return (
                <div key={item.title}>
                    <a href={`/album/${item.displayTitle}`}>{item.displayTitle}</a>
                </div>
            )
        });

        const songs = result.songs?.map(function (item: any) {
            return (
                <div key={item.id}>
                    <SongLink song={item}></SongLink>
                </div>
            )
        });

        const searchResult = <div id="search-result" style={{ maxWidth: 18 + 'rem', padding: '20px' }}>
            {
                (() => {
                    if (result.artists?.length > 0) {
                        return <div><h2>Artists</h2></div>
                    }
                })()
            }
            {artists}
            {
                (() => {
                    if (result.albums?.length > 0) {
                        return <div><h2>Albums</h2></div>
                    }
                })()
            }
            {albums}
            {
                (() => {
                    if (result.songs?.length > 0) {
                        return <div><h2>Songs</h2></div>
                    }
                })()
            }
            {songs}
        </div>

        if ((artists && artists?.length > 0)
            || (albums && albums?.length > 0)
            || (songs && songs?.length > 0)) {
            setResultView(searchResult)
        }
        else {
            setResultView(<div style={{ color: 'red' }}>your search returns no match</div>)
        }
    }

    return (
        <Container>
            <h1>Hey!! you searched for {term}</h1>
            {resultView}
        </Container>
    )
}

export default SearchResult