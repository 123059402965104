import React from 'react';
import CognitoService from '../services/cognitoService';
import ArtistLink from '../components/artist-link';
import SongLink from '../components/song-link';
import { Button, Container } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import * as apiService from '../services/api'
import AdComponent from '../components/adComponent';

class Search extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        const isAdmin = CognitoService.isAdmin();

        this.state = {
            searchterm: '',
            searchResponse: {},
            showResult: false,
            usergroup: isAdmin ? 'admin' : ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = `Myanmar Lyrics | Search`;
    }

    handleChange(event: any) {
        const currentComponent = this;
        currentComponent?.setState({ searchterm: event.target.value });
    }

    handleSubmit(event: any) {
        const currentComponent = this;
        if (!currentComponent.state.searchterm) {
            alert('please put something to search');
        }

        if (currentComponent.state.searchterm) {
            apiService.getSearchResult(currentComponent.state.searchterm, currentComponent.state.usergroup)
                .then(
                    (result) => {
                        currentComponent.setState({ showResult: true });
                        currentComponent.setState({ searchResponse: result });
                    }
                );
        }

        event.preventDefault();
    }

    searchForm = () => {
        return (
            <form onSubmit={this.handleSubmit}>
                <input
                    style={{ width: '200px', height: '37px' }}
                    value={this.state.searchterm}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="artist or song title"
                    aria-label="Search">
                </input>
                <Button variant="contained" type="submit">Search</Button>
            </form>
        )
    }

    render() {

        const artists = this.state.searchResponse.artists?.map(function (item: any) {
            return (
                <div key={item.id}>
                    <ArtistLink artist={item}></ArtistLink>
                </div>
            )
        });

        const albums = this.state.searchResponse.albums?.map(function (item: any) {
            return (
                <div key={item.title}>
                    <a href={`/album/${item.displayTitle}`}>{item.displayTitle}</a>
                </div>
            )
        });

        const songs = this.state.searchResponse.songs?.map(function (item: any) {
            return (
                <div key={item.id}>
                    <SongLink song={item}></SongLink>
                </div>
            )
        });

        const searchResult = <div id="search-result" style={{ maxWidth: 18 + 'rem', padding: '20px' }}>
            {
                (() => {
                    if (this.state.searchResponse.artists?.length > 0) {
                        return <div><h2>Artists</h2></div>
                    }
                })()
            }
            {artists}
            {
                (() => {
                    if (this.state.searchResponse.albums?.length > 0) {
                        return <div><h2>Albums</h2></div>
                    }
                })()
            }
            {albums}
            {
                (() => {
                    if (this.state.searchResponse.songs?.length > 0) {
                        return <div><h2>Songs</h2></div>
                    }
                })()
            }
            {songs}
        </div>

        let resultView;
        if ((artists && artists?.length > 0)
            || (albums && albums?.length > 0)
            || (songs && songs?.length > 0)) {
            resultView = searchResult;
        }
        else {
            resultView = <div style={{ color: 'red' }}>your search returns no match</div>
        }

        return (
            <Container>
                <h1>Search</h1>
                {this.searchForm()}
                <AdComponent></AdComponent>
                {this.state.showResult ? resultView : null}
                <h2>Search guide</h2>
                <p><b>search using song title or artist name. you can search with either in English (Burglish) or in Myanmar unicode font.</b></p>
                <p>zaw win htut<DoneIcon></DoneIcon></p>
                <p>ဇော်၀င်းထွဠ်<DoneIcon></DoneIcon></p>
                <p>gypsy moe tain<DoneIcon></DoneIcon></p>
                <p>ဂျစ်ပစီမိုးတိမ်<DoneIcon></DoneIcon></p>
                <p><b>Do not combine artist name and song title in your search</b></p>
                <p>gypsy moe tain zaw win htut<CloseIcon></CloseIcon></p>
                <p>gypsy moe tain - zaw win htut<CloseIcon></CloseIcon></p>
                <p>You can also search with part of the name or full name for both artists and songs</p>
                <p>zaw<DoneIcon></DoneIcon></p>
                <p>This will show you all the artists that have the word 'zaw' in them</p>
                <p>For example,</p>
                <p>ဇော်၀င်းထွဠ်</p>
                <p>သားဇော်</p>
                <p>ဟိန်းဇော်</p>
                <p>နီနီခင်ဇော်</p>
                <p>ဇော်ဝမ်း</p>
                <p>မောင်မောင်ဇော်လတ်</p>
                <p>စိုးဇော်ပိုင်</p>
                <p>ဇော်ပိုင်</p>
                <p>နန့်ချစ်နဒီဇော်</p>
            </Container>
        )
    }
}

export default Search;