import React from 'react';

class Carousel extends React.Component<any, any> {
    render() {
        const title = "myanmarlyrics.net";

        return (
            <div>
                <img
                    title={title} src={`https://myanmarlyrics-public.s3-eu-west-1.amazonaws.com/webapp/notice.jpg`}
                    alt="myanmar lyrics and chords"
                    style={{ maxWidth: '100%', overflow: 'hidden' }}
                />
            </div>
        )
    }


}

export default Carousel;