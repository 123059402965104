import * as React from 'react';
import SongLink from '../song-link';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { CircularProgress } from '@material-ui/core';
import * as apiService from '../../services/api'

class LatestUploadsCard extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
            sample: {
                songs: []
            }
        }
    }

    //trying out api gateway lambda
    componentDidMount() {
        apiService.getlatestuploads()
        .then(
            (result) => {
                this.setState({
                    songs: result
                });
            }
        )
    }

    render() {
        const slicesongs = this.state.songs?.slice(0, 10);

        const songs = slicesongs?.map(function (item: any) {
            return (
                <div key={item.id}>
                    <SongLink song={item}></SongLink>
                </div>
            )
        });

        const body = <div>
                        <h2>
                            Latest Uploads&nbsp;
                            <CloudUploadIcon style={{ marginBottom: -5 }}></CloudUploadIcon>
                        </h2>
                        <div style={styles.items}>{songs && songs.length > 0 ? songs : <CircularProgress />}</div>
                    </div>
        return (
            <div style={styles.container}>
                    {body}
            </div>
        );
    }
}

export default LatestUploadsCard

const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        textAlign: 'center',
    } as React.CSSProperties,
    items: {
        textAlign: 'left',
        padding: '20px'
    } as React.CSSProperties,
}