import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import './App.css';

import Song from './pages/song';
import Artist from './pages/artist';
import Album from './pages/album';
import Home from './pages/home';
import Footer from './components/site/footer';
import Terms from './pages/terms';
import Login from './pages/login';
import Register from './pages/register';
import User from './pages/user';
import ForgotPassword from './pages/forgotpasswordv2';
import ForgotPasswordConfirmation from './components/auth/forgotPasswordConfirmation';
import Albums from './pages/albums';
import MostPopular from './pages/mostpopular';
import Search from './pages/search';
import MostFavourited from './pages/mostfavourited';
import Compatibility from './pages/compatibility';
import Artists from './pages/artists';
import { CssBaseline, makeStyles } from '@material-ui/core';
import Navbarv2 from './components/site/navbarv2';
import UserFavourites from './components/auth/userFavourites';
import SearchResult from './components/search-result';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    color: 'white',
    // height: '100vh',
    // overflow: 'auto',
  }
}));

function App() {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline></CssBaseline>
      <BrowserRouter>
        <Navbarv2></Navbarv2>
        <div className={classes.content}>
          <div className={classes.appBarSpacer} />
          <div>
            <Switch>
              <Route path="/song/:id?" component={Song} ></Route>
              <Route path="/album/:title?" component={Album} ></Route>
              <Route path="/artist/:name?" component={Artist} ></Route>

              <Route path="/artists" component={Artists} ></Route>
              <Route path="/albums" component={Albums} ></Route>
              <Route path="/mostpopular" component={MostPopular} ></Route>
              <Route path="/mostfavourited" component={MostFavourited} ></Route>

              <Route path="/terms" component={Terms}></Route>
              <Route path="/search" component={Search}></Route>
              <Route path="/compatibility" component={Compatibility}></Route>

              <Route path="/login" component={Login}></Route>
              <Route path="/register" component={Register}></Route>
              <Route path="/user" component={User}></Route>
              <Route path="/forgotpassword" component={ForgotPassword}></Route>
              <Route path="/forgotpasswordconfirmation" component={ForgotPasswordConfirmation}></Route>

              <Route path="/settings" component={User}></Route>
              <Route path="/myfavourites" component={UserFavourites}></Route>
              <Route path="/searchresult" component={SearchResult}></Route>

              <Route path="/" component={Home} ></Route>
            </Switch>
          </div>
          <Footer></Footer>
        </div>

      </BrowserRouter>
    </div>
  );
}

export default App;
