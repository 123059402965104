import { Button, Container } from "@material-ui/core"
import React from "react"

const AndroidApp = () => {

    return (
        <Container>
            <h1>Mobile app for Android</h1>
            <p>We would like to introduce you with our new Android app by Myanmarlyrics.net.</p>
            <Button
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.mobifirst.myanmarlyrics"
                variant="contained"
                color="secondary">Google Play
            </Button>
            <p>If you have previously installed the apk version of our app, please uninstall it and install the new version from Google Play store. This will help you to automatically download updates.</p>
            <hr />
        </Container>
    )
}

export default AndroidApp