import * as React from 'react';
import poolData from '../../services/config';

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import SongLink from '../song-link';
import { CircularProgress, Container } from '@material-ui/core';

import * as apiService from '../../services/api'
import AdComponent from '../adComponent';

class UserFavourites extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
            songs: [],
            showLoading: false
        }
    }

    componentDidMount() {

        this.setState({showLoading: true})

        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        let cognitoUser = userPool.getCurrentUser();
        const username = cognitoUser?.getUsername();

        apiService.getUserFavourites(username)
        .then(
            (result) => {
                this.setState({
                    songs: result,
                    showLoading: false
                });
            }
        )
    }

    favourites = () => {

        let songs = this.state.songs?.map(function (item: any) {
            return (
                <div key={item.id}>
                    <SongLink song={item}></SongLink>
                </div>
            )
        });

        return (
            <div>
                <h1>My Favourites</h1>
                {this.state.showLoading ? <CircularProgress />: null}
                {(songs && songs.length > 0) ? songs : (this.state.showLoading ? null : 'you have no favourites')}
                <AdComponent></AdComponent>
            </div>
        )
    }

    render() {
        return (
            <Container>
                {this.favourites()}
            </Container>
        );
    }
}

export default UserFavourites