import React from 'react';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import poolData from '../../services/config';
import { Button, Container, TextField, withStyles } from '@material-ui/core';

const styles = (theme: any) => ({
    root: {
        '& > *': {
          margin: theme.spacing(1),
          width: '35ch',
        },
    },
    textField: {
        '& .MuiInputBase-input': {
            color: 'white',
        },
        width: '35ch',
    }
});

class ChangePassword extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
            oldpassword: '',
            newpassword: '',
            confirmpassword: ''
        }
    }

    handleChange = (event: any) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }

    handleSubmit = () => {
        const _this = this;

        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        let cognitoUser = userPool.getCurrentUser();

        const oldpassword = _this.state.oldpassword;
        const newpassword = _this.state.newpassword;
        const confirmpassword = _this.state.confirmpassword;

        if (!oldpassword) {
            alert('old password is required');
        } else if (!newpassword) {
            alert('new password is required');
        } else if (!confirmpassword) {
            alert('please confirm your new password');
        } else if (newpassword !== confirmpassword) {
            alert('password not match');
        } else {
            cognitoUser?.getSession(function (err: any, session: any) {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                    return;
                }
                cognitoUser?.changePassword(oldpassword, newpassword, function (err, result) {
                    if (err) {
                        console.log(err);
                        alert(err.message || JSON.stringify(err));
                    } else {
                        console.log('call result: ' + result);
                        alert('password changed');
                    }
                });
            });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Container>
                <h1>Change Password</h1>
                <form className={classes.root}>
                    <div>
                        <TextField
                            className={classes.textField}
                            onChange={this.handleChange}
                            required
                            name="oldpassword"
                            placeholder="Old Password"
                            type="password"
                            inputProps={{ maxLength: 20 }}>
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            className={classes.textField}
                            onChange={this.handleChange}
                            required
                            name="newpassword"
                            placeholder="New Password"
                            type="password"
                            inputProps={{ maxLength: 20 }}>
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            className={classes.textField}
                            onChange={this.handleChange}
                            required
                            name="confirmpassword"
                            placeholder="Confirm Password"
                            type="password"
                            inputProps={{ maxLength: 20 }}>
                        </TextField>
                    </div>
                    <Button variant="contained" color="primary" onClick={this.handleSubmit}>
                        Change Password
                    </Button>
                </form>
            </Container>
        );
    }
}

export default withStyles(styles)(ChangePassword)