import React from 'react';

class LyricsImage extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
        }
    }

    render() {

        const song = this.props.song;
        if (!song) return '';

        const apiUrl = `${process.env.REACT_APP_API_URL}`;
        const linkWebp = `${apiUrl}song/getfile?id=${song.id}&format=webp`;
        const linkFallback = `${apiUrl}song/getfile?id=${song.id}`;

        let contextMenu = function (e: any) {
            e.preventDefault();
            return false;
        }

        return (
            // <a
            // href={link}
            // target="_blank"
            // >
            <picture>
                <source srcSet={linkWebp} type="image/webp"></source>
                <source srcSet={linkFallback} type="image/jpeg"></source>
                <img style={styles.image} className={'img-fluid'} src={linkFallback} alt={song.title} onContextMenu={contextMenu}></img>
            </picture>
            // </a>
        );
    }
}

export default LyricsImage

const styles = {
    image: {
        maxWidth: '720px',
        width: '100%'
    } as React.CSSProperties,
}
