import * as React from 'react';
import poolData from '../services/config';

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { Button, Container, TextField, withStyles } from '@material-ui/core';

// import {
// 	CognitoUserPool,
// 	CognitoUserAttribute,
// 	CognitoUser,
// } from 'amazon-cognito-identity-js';

const styles = (theme: any) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '35ch',
        },
    },
    textField: {
        '& .MuiInputBase-input': {
            color: 'white',
        },
        width: '35ch',
    }
});

class Register extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
            email: '',
            password: ''
        }
    }

    componentDidMount() {
        document.title = `Myanmar Lyrics | Register`;
    }

    handleChange = (event: any) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }

    register = () => {
        const currentComponent = this;
        const email = currentComponent.state.email;
        const password = currentComponent.state.password;

        if (!(email && password)) {
            alert('both email and password are required')
            return;
        }

        this.cognitoSignup(email, password)
    }

    cognitoSignup = (email: string, password: string) => {
        const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        const attributeList = [];
        const dataEmail = {
            Name: 'email',
            Value: email
        }

        const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);
        attributeList.push(attributeEmail);

        userPool.signUp(
            email, password, attributeList, attributeList,
            function (err: any, result: any) {
            if (err) {
                alert(err.message);
            } else {
                const message = `you have successfully registered. You need to confirm your email before you can login.`
                alert(message)
                document.location.href = "/login";
            }
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Container>
                <h1>Register</h1>
                <form className={classes.root} autoComplete="off">
                    <div>
                        <TextField
                            className={classes.textField}
                            onChange={this.handleChange}
                            required
                            name="email"
                            placeholder="Email"
                            id="email"
                            type="text"
                            inputProps={{ maxLength: 120 }}>
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            className={classes.textField}
                            onChange={this.handleChange}
                            required
                            name="password"
                            placeholder="Password"
                            id="password"
                            type="password"
                            inputProps={{ maxLength: 20 }}>
                        </TextField>
                    </div>
                    <div>
                        <i>password must be at least 8 characters long and must include one number, one lower case and one upper case</i>
                    </div>
                    <Button variant="contained" color="primary"
                        type="button"
                        onClick={() => this.register()}>
                        Register
                    </Button>
                </form>
            </Container>
        );
    }
}

export default withStyles(styles)(Register)