import React from 'react';
import poolData from '../../services/config';

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { Button, TextField, withStyles } from '@material-ui/core';

const styles = (theme: any) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '35ch',
        },
    },
    textField: {
        '& .MuiInputBase-input': {
            color: 'white',
        },
        width: '35ch',
    }
});

class ForgotPasswordConfirmation extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
            // username: '',
            code: '',
            newpassword: ''
        }
    }

    confirm = () => {

        if (!(this.state.code && this.state.newpassword)) {
            alert('missing code or password');
            return;
        }

        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

        let userData = {
            Username: this.props.username,
            Pool: userPool,
        };

        let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

        cognitoUser.confirmPassword(this.state.code, this.state.newpassword, {
            onSuccess() {
                alert('password confirmed! go back to Login page to continue')
            },
            onFailure(err) {
                alert(err.message);
            },
        });
    }

    componentDidMount() {
    }

    handleChange = (event: any) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }

    render() {
        const { classes } = this.props;
        return (
            <form className={classes.root} autoComplete="off">
                <div>
                    <i>Once you received the code, use the form below to reset your password</i>
                </div>
                <div>
                    <TextField
                        className={classes.textField}
                        required
                        name="code"
                        placeholder="Code"
                        onChange={this.handleChange}
                        id="code">
                    </TextField>
                </div>
                <div className="form-group">
                    <TextField
                        className={classes.textField}
                        required
                        id="new_password"
                        name="newpassword"
                        placeholder="New Password"
                        onChange={this.handleChange}
                        type="password">
                    </TextField>
                    <div><i>password must be at least 8 characters long and must include one number, one lower case and one upper case</i></div>
                </div>

                <Button variant="contained" color="primary"
                    onClick={() => { this.confirm() }}>
                    Send
                    </Button>
            </form>
        );
    }
}

export default withStyles(styles)(ForgotPasswordConfirmation)