import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AdComponent from '../components/adComponent';
import SongLink from '../components/song-link'
import * as apiService from '../services/api'

function getCookie(cname: any) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

const Artist = () => {

    const { name }: any = useParams();
    const [artist, setArtist] = useState<any>({})

    useEffect(() => {
        apiService.getArtistByName(name)
            .then(
                (result) => {
                    setArtist(result)
                    document.title = `Myanmar Lyrics | ${result.nameInBurglish}`
                }
            )
            .catch((error) => {
                console.log(error)
            })
    }, [])

    let albums = artist.albums?.map(function (item: any, index: number) {
        return (
            <div key={index}>
                <a
                    href={`/album/${item.displayTitle.replace(/\s+/g, '-')}`}
                >
                    {item.displayTitle}
                </a>
            </div>
        )
    })

    let songs = artist.songs?.map(function (item: any) {
        return (
            <div key={item.id}>
                <div>
                    <SongLink song={item}></SongLink>
                </div>
            </div>
        )
    });

    const lang = getCookie('lang')

    return (
        <div style={styles.container}>
            <div>
                <div style={styles.left}>
                    <img style={styles.img} src={'https://myanmarlyrics-public.s3-eu-west-1.amazonaws.com/webapp/empty.jpg'}></img>
                </div>
                <div style={styles.right}>
                    <h1>{lang === 'mm' ? artist.displayName : artist.nameInBurglish}</h1>
                    <h2>Albums</h2>
                    {albums && albums.length > 0 ? albums : 'no album'}
                    <AdComponent></AdComponent>
                    <h2>Songs</h2>
                    {songs && songs.length > 0 ? songs : 'no song'}
                </div>
            </div>
        </div>
    )
}

export default Artist

const styles = {
    container: {
        padding: '20px',
        display: 'flex',
        justifyContent: 'center',
        lineHeight: '200%',
    } as React.CSSProperties,
    img: {
        width: '150px',
        height: 'auto',
        margin: '10px',
    } as React.CSSProperties,
    left: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: '50px',
    } as React.CSSProperties,
    right: {
    } as React.CSSProperties,
}