import * as React from 'react';
import poolData from '../services/config';

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import ForgotPasswordConfirmation from '../components/auth/forgotPasswordConfirmation';
import { Button, Container, TextField, withStyles } from '@material-ui/core';

const styles = (theme: any) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '35ch',
        },
    },
    textField: {
        '& .MuiInputBase-input': {
            color: 'white',
        },
        width: '35ch',
    }
});

class ForgotPassword extends React.Component<any, any>
{
    constructor(props: any) {
        super(props);

        this.state = {
            username: ''
        }
    }

    sendCode = () => {
        if (!this.state.username) {
            alert('Please enter a valid username');
            return;
        }

        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

        let userData = {
            Username: this.state.username,
            Pool: userPool,
        };

        let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

        cognitoUser?.forgotPassword({
            onSuccess: function (data: any) {
                alert('if your email is registered with us, you should receive an email from us with the code to reset your password. check your email and use the code to reset your password using the form below')
            },
            onFailure: function (err: any) {
                alert(err.message || JSON.stringify(err));
            }
        });
    }

    componentDidMount() {
    }

    handleChange = (event: any) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }

    render() {
        const { classes } = this.props;

        return (
            <Container>
                <form className={classes.root} autoComplete="off">
                    <div>
                        <div>
                            <i>Enter your registered email to reset your password. We will send you confirmation code to reset your password</i>
                        </div>
                        <div>
                            <TextField
                                className={classes.textField}
                                required
                                placeholder="Email"
                                name="username"
                                onChange={this.handleChange}
                                id="code">
                            </TextField>
                        </div>
                    </div>

                    <Button variant="contained" color="primary"
                        onClick={() => { this.sendCode() }}>Reset Password
                    </Button>
                </form>
                <ForgotPasswordConfirmation username={this.state.username}></ForgotPasswordConfirmation>
            </Container>
        );
    }
}

export default withStyles(styles)(ForgotPassword)