import * as apiService from '../../services/api'

function getPopularArtistsSuccess(artists) {
    return { type: 'GET_POPULAR_ARTISTS', artists }
}

export function getPopularArtists() {
    return function (dispatch) {
        return apiService.getPopularArtists()
            .then(artists => {
                dispatch(getPopularArtistsSuccess(artists))
            }).catch(error => {
                throw error
            })
    }
}