import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import poolData from './config';

class CognitoService
{
    static getUserId() {
        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        let cognitoUser = userPool.getCurrentUser();
        return cognitoUser? cognitoUser?.getUsername(): '';
    }

    static getUser() {
        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        let cognitoUser = userPool.getCurrentUser();
        return cognitoUser;
    }

    static isAdmin() {
        let isAdmin = false;
        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        let cognitoUser = userPool.getCurrentUser();
        if(cognitoUser != null)
        {
            cognitoUser.getSession(function(err: any, session: CognitoUserSession) {
                if(err) {
                    console.log(err);
                    return;
                }

                let groups = session.getAccessToken().decodePayload()['cognito:groups'];
                let isAdminFunc = function(list: any) {
                    return list?.some(function (item: any) {
                        if(item === 'admin') {
                            return true;
                        }
                        return false;
                    });
                }

                isAdmin = isAdminFunc(groups);
            })
        }
        return isAdmin;
    }
}

export default CognitoService;