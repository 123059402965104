import React from 'react';

declare global {
    interface Window {
        adsbygoogle:any;
    }
}

export default class AdComponent extends React.Component {
  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

render () {
    return (
        <div>
            <ins className="adsbygoogle"
                style={{display:'block'}}
                data-ad-client="ca-pub-3609393306554873"
                data-ad-slot="1786356241"
                data-ad-format="auto"
                data-full-width-responsive="true">
            </ins>
        </div>
    );
  }
}