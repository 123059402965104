import * as React from 'react';
import AlbumIcon from '@material-ui/icons/Album';
import { CircularProgress, Container } from '@material-ui/core';
import * as apiService from '../services/api'
import AlbumList from '../components/albumList';
import AdComponent from '../components/adComponent';

class Albums extends React.Component<any, any>
{
    state = {
        albums: []
    }

    componentDidMount() {
        apiService.getallalbums()
            .then(
                (result) => {
                    this.setState({
                        albums: result
                    });
                }
            )
        document.title = `Myanmar Lyrics | Albums`;
    }

    render() {
        return (
            <Container>
                <h2>Albums&nbsp;<AlbumIcon></AlbumIcon></h2>
                <div>{this.state.albums && this.state.albums.length > 0
                    ? <AlbumList albums={this.state.albums}></AlbumList>
                    : <CircularProgress />}
                </div>
            </Container>
        );
    }
}

export default Albums