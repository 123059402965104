import React from 'react';
import AlbumsCard from '../components/home/albumsCard';
import ArtistsCard from '../components/home/artistsCard';
import Carousel from '../components/home/carousel';
import LatestUploadsCard from '../components/home/latestuploadscard';
import styled from 'styled-components';
import AndroidApp from '../components/home/androidapp';

const Boxes = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #282c34;

    @media (max-width: 800px) {
        flex-direction: column;
    }
`

class Home extends React.Component
{
    render() {
        let body = <div>
                        <Carousel></Carousel>
                        <Boxes>
                            <div style={boxes}>
                            <ArtistsCard></ArtistsCard>
                            </div>
                            <div style={boxes}>
                            <AlbumsCard></AlbumsCard>
                            </div>
                            <div style={boxes}>
                            <LatestUploadsCard></LatestUploadsCard>
                            </div>
                        </Boxes>
                        <AndroidApp></AndroidApp>
                    </div>
        return (
            <React.Fragment>
                {body}
            </React.Fragment>
        );
    }
}

export default Home

const boxes = {
    width: '300px',
    height: '400px',
    border: '1px solid #646e82',
    backgroundColor: '#282c34',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: '20px',
} as React.CSSProperties;