
import React from "react"
import clsx from 'clsx';
import { AppBar, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, Drawer, List, Divider, IconButton, InputBase, fade, Hidden } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import AlbumIcon from '@material-ui/icons/Album';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SearchIcon from '@material-ui/icons/Search';

import Auth from "../auth/auth";
import SimpleListMenu from "./language-menu";

import { useHistory } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#343a40',
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 15,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        paddingRight: '25px',
        paddingTop: '7px',
    },
    drawerPaper: {
        position: 'absolute',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: '#282c34',
        minHeight: '100vh',
        overflow: 'hidden',
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(0),
        },
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    icon: {
        color: 'grey',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const Navbarv2 = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const history = useHistory();
    const onKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            if (e.target.value) {
                const cleanEncoded = encodeURI(e.target.value)
                history.push(`/searchresult?q=${cleanEncoded}`);

                e.preventDefault()
            }
        }
    }

    return (
        <div>
            <React.Fragment>
                <AppBar
                    position="absolute"
                    className={clsx(classes.appBar, open && classes.appBarShift)}
                >
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Hidden only={['xs', 'sm']}>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                className={classes.title}
                            >
                                <NavLink to={'/'}>
                                    <img
                                        alt="myanmar lyrics logo"
                                        title="myammar lyrics logo"
                                        style={{ width: '130px' }}
                                        src={'/logo.png'} />
                                </NavLink>
                            </Typography>
                        </Hidden>
                        <form autoComplete="off">
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder="Search…"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                    onKeyPress={onKeyPress}
                                />
                            </div>
                        </form>
                        <SimpleListMenu></SimpleListMenu>
                    </Toolbar>
                </AppBar>

                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon style={{ color: 'white' }} />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        <NavLink style={{ color: 'grey' }} exact={true} to={'/'} activeStyle={{ color: 'white' }} onClick={toggleDrawer}>
                            <ListItem button>
                                <ListItemIcon>
                                    <HomeIcon className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText primary="Home" />
                            </ListItem>
                        </NavLink>

                        <NavLink style={{ color: 'grey' }} to={'/artists'} activeStyle={{ color: 'white' }} onClick={toggleDrawer}>
                            <ListItem button>
                                <ListItemIcon>
                                    <PeopleAltIcon className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText primary="Artists" />
                            </ListItem>
                        </NavLink>

                        <NavLink style={{ color: 'grey' }} to={'/albums'} activeStyle={{ color: 'white' }} onClick={toggleDrawer}>
                            <ListItem button>
                                <ListItemIcon>
                                    <AlbumIcon className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText primary="Albums" />
                            </ListItem>
                        </NavLink>

                        <NavLink style={{ color: 'grey' }} to={'/mostfavourited'} activeStyle={{ color: 'white' }} onClick={toggleDrawer}>
                            <ListItem button>
                                <ListItemIcon>
                                    <FavoriteIcon className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText primary="Most Favourited" />
                            </ListItem>
                        </NavLink>

                        <NavLink style={{ color: 'grey' }} to={'/search'} activeStyle={{ color: 'white' }} onClick={toggleDrawer}>
                            <ListItem button>
                                <ListItemIcon>
                                    <SearchIcon className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText primary="Search" />
                            </ListItem>
                        </NavLink>

                        <a
                            target="_blank"
                            href={'https://myanmarlyrics-public.s3-eu-west-1.amazonaws.com/webapp/myanmarlyrics_net_user_guide.pdf'}
                            onClick={toggleDrawer}
                            rel="noreferrer">
                            <ListItem button>
                                <ListItemIcon>
                                    <InfoIcon className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText style={{ color: 'grey' }} primary="User guide" />
                            </ListItem>
                        </a>
                    </List>
                    <Divider />
                    <Auth></Auth>
                </Drawer>
            </React.Fragment>
        </div>
    )
}

export default Navbarv2