import React from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import poolData from '../../services/config';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { Button, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';
import FavoriteIcon from '@material-ui/icons/Favorite';


class Auth extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoggedIn: false,
            email: ''
        }
    }

    componentDidMount() {
        this.retrieveUser();
    }

    retrieveUser = () => {

        const currentComponent = this;

        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        let cognitoUser = userPool.getCurrentUser();

        if (cognitoUser != null) {
            cognitoUser.getSession(function (err: any, session: any) {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                    return;
                }

                currentComponent.setState({ isLoggedIn: session.isValid() })

                cognitoUser?.getUserAttributes(function (err, attributes) {
                    if (err) {
                        alert(err.message || JSON.stringify(err));
                        return;
                    } else {
                        const item: any = attributes?.filter((item) => item.getName() === 'email');

                        currentComponent.setState({ email: item[0].getValue() });
                    }
                });
            })
        }
    }

    signOut = () => {
        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        let cognitoUser = userPool.getCurrentUser();
        cognitoUser?.signOut();

        document.location.href = "/";
    }

    registeredUser = () => {
        return (
            <div style={{ color: 'white', padding: '10px' }}>
                <ListSubheader style={{ color: 'white' }}>{this.state.email}</ListSubheader>
                <NavLink style={{ color: 'grey' }} to={'/myfavourites'} activeStyle={{ color: 'white' }}>
                    <ListItem button>
                        <ListItemIcon>
                            <FavoriteIcon style={{ color: 'grey' }} />
                        </ListItemIcon>
                        <ListItemText primary="My Favourites" />
                    </ListItem>
                </NavLink>
                <NavLink style={{ color: 'grey' }} to={'/settings'} activeStyle={{ color: 'white' }}>
                    <ListItem button>
                        <ListItemIcon>
                            <SettingsIcon style={{ color: 'grey' }} />
                        </ListItemIcon>
                        <ListItemText primary="Settings" />
                    </ListItem>
                </NavLink>
                <Button style={{ backgroundColor: 'grey' }}
                    onClick={() => this.signOut()}
                    href={'#'}>
                    Logout&nbsp;
                        <ExitToAppIcon></ExitToAppIcon>
                </Button>
            </div>
        )
    }

    guest = () => {
        return (
            <div>
                &nbsp;
                <Button variant="outlined" color="primary"
                    href={'/login'}>
                    Login
                </Button>
                &nbsp;
                <Button variant="outlined" color="secondary"
                    href={'/register'}>
                    Register
                </Button>
            </div>
        )
    }

    greeting = (props: any) => {
        const isLoggedIn = props.isLoggedIn;
        if (isLoggedIn) {
            return (
                <div>
                    {this.registeredUser()}
                </div>
            )
        }

        return (
            <div>
                {this.guest()}
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <this.greeting isLoggedIn={this.state.isLoggedIn}></this.greeting>
            </React.Fragment>
        )
    }
}

export default Auth;